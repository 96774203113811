import React from "react";
import Services from "./Services/Services";
import Projects from "./Projects/Projects";
import Review from "./Review/Review";
import Engage from "./Engage/Engage";
import Banner from "./Banner/Banner";
import Contact from "./Contact/Contact";
import Courses from "./Courses/Courses";
import Topic from "./Topic/Topic";
import Header from "./Header/Header";
import PRTPAds from "./PRTPAds/PRTPAds";
import Footer from "../Shared/Footer/Footer";
import ScrollNav from "../Shared/ScrollNav/ScrollNav";

const LandingPage = ({ isLoggedIn, adsId }) => {
  return (
    <div style={{ backgroundColor: "#f3f5f9" }}>
      <Header isLoggedIn={isLoggedIn} />
      <ScrollNav isLoggedIn={isLoggedIn} />
      <Banner isLoggedIn={isLoggedIn} />
      <PRTPAds isLoggedIn={isLoggedIn} />
      <Topic />
      <Engage />
      <Courses />
      <Services />
      <Projects adsId={adsId} />
      <Review />
      <Contact adsId={adsId} />
      <Footer />
    </div>
  );
};

export default LandingPage;
