import React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import CardContent from "@mui/material/CardContent";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import { Pagination, Autoplay } from "swiper";
import { Paper } from "@mui/material";
import ReactReadMoreReadLess from "react-read-more-read-less";
import style from "./Review.module.css";
import Link from "next/link";
import { Button } from "react-bootstrap";

const Review = () => {
  return (
    <Container>
      <Row>
        <h4 className="fw-bold text-primary">Our Reviews</h4>
        <Col>
          <Swiper
            slidesPerView={3}
            spaceBetween={30}
            slidesPerGroup={1}
            breakpoints={{
              320: {
                slidesPerView: 1,
                spaceBetween: 20,
              },
              640: {
                slidesPerView: 1,
                spaceBetween: 20,
              },
              768: {
                slidesPerView: 2,
                spaceBetween: 0,
              },
              1024: {
                slidesPerView: 3,
                spaceBetween: 0,
              },
            }}
            autoplay={{
              delay: 10000,
              disableOnInteraction: false,
            }}
            pagination={{
              clickable: true,
              dynamicBullets: true,
            }}
            modules={[Pagination, Autoplay]}
            className="mySwiper"
          >
            {reviewData.map((review, idx) => (
              <SwiperSlide key={idx} className="mb-4">
                <Paper elevation={3} className="p-2 m-1 rounded-4">
                  <CardContent className="d-flex justify-content-between align-items-start">
                    <div className=" text-break">
                      <b>{review.name}</b>
                      <span className="d-block fs-6">{review.profession}</span>
                      <span className="d-block">{review.dept}</span>
                      <small>{review.organization}</small>
                    </div>
                    <div>
                      <picture>
                        <img
                          className="rounded-pill"
                          height={90}
                          width={90}
                          src={review.img}
                          alt={review.name}
                          title={review.name}
                        />
                      </picture>
                    </div>
                  </CardContent>

                  <div className={`${style.info} p-1`}>
                    <picture>
                      <img
                        className={style.quote_img}
                        src={quote}
                        alt="quote_img"
                        title="quote_img"
                      />
                    </picture>
                    <ReactReadMoreReadLess
                      className="text-break"
                      charLimit={100}
                      readMoreText={"Read more"}
                      readLessText={"Read less"}
                    >
                      {review.review}
                    </ReactReadMoreReadLess>
                  </div>
                </Paper>
              </SwiperSlide>
            ))}
          </Swiper>
        </Col>
      </Row>

      <div className="text-center my-5">
        <h2>
          Advance Your Research and Join a Community
          <br /> of Million&apos;s of Scientists
        </h2>

        <Link href="/user/login">
          <Button
            className={`${style.btn_hover} ${style.color}`}
            variant="light"
          >
            Find More
          </Button>
        </Link>
      </div>
    </Container>
  );
};

export default Review;

const quote =
  "https://img.icons8.com/fluency-systems-filled/48/null/quote-left.png";

const reviewData = [
  {
    img: "https://i.ibb.co/LPM4gNR/Mohammad-Amzad-Hossain.png",
    name: "Mohammad Amzad Hossain",
    profession: "Professor",
    dept: "Department of Economics",
    organization: "Jahangirnagar University, Dhaka 1342, Bangladesh",
    review:
      "Bangladesh is improving in terms of economic indicators very quickly over the last dictates, but further improvement is also required to enhance  food security and nutritional security and educational security and  in terms of fulfill all basic needs and for this for this reason, we need to do or we need to have the scope for research in different social economic activities and social economic programs as undertaken by the government and Research Rider is such and institution which helps you to make you a good researcher in this respect and Research Rider has developed and important and timely enhance of research methodology. I hope the Research rider has the potential to step forward successfully and it will help you to analyse yourself in terms of learning and doing the potential research that will help the country towards a development on by 2041.",
  },

  // {
  //   img: "https://i.ibb.co/vzsXKjh/idmv-azad-socio.jpg",
  //   name: "Md. Abul Kalam Azad",
  //   profession: "Assistant Professor",
  //   dept: "Institute of Disaster Management and Vulnerability ",
  //   organization: "University of Dhaka",
  //   review:
  //     "Building resilience is expected to be more challenging due to uncertain shocks. local knowledge is a steppingstone to utilize existing resources, generate innovative innovations, and reinforcing community resilience to environmental risks and pressures. The development process of knowledge of locals is strongly whetted by socioeconomic and environmental values, power dimensions, and institutional dynamics.I hope the Research Rider has potential to step frowrad and develop our country.",
  // },

  {
    img: "https://researchrider.s3.amazonaws.com/media/user_profile_pic/MD._MAHMUDUL_HASSAN.png",
    name: "Dr. Md Mahmudul Hassan",
    profession: "Associate Professor",
    dept: "Department of Economics",
    organization: "Bangladesh University of Business and Technology (BUBT)",
    review:
      "For any profession research must  play a vital role in any economy of the world. Nowadays, the develop countries of the world give first priorities in research. Not only teaching profession but also new innovations needs properly research in Bangladesh. But it is badly say that there is so poor opportunity to do research in any field as well as teaching profession. For improving research knowledge and gathering research technics play an important role in this training program “Professional Research Training Program (PRTP)”.“I wish every success PRTP and Research Rider all time.",
  },
  {
    img: "https://i.ibb.co/zHMJPdN/K-M-Sujauddin.png",
    name: "K. M. Sujauddin",
    profession: "Assistant Professor",
    dept: "Department of Sociology",
    organization: "Jagannath University, Dhaka-1100 Bangladesh",
    review:
      "Research is absolutely a creative and skillful jobs that show the path of vivid thinking as well. It is well connected to the development of the nation.For the progression of both mental and physical structure, we need to emphasis on research related issues. We have so many drawbacks in this sector, but yet i do beleive that there are so many young  researchers are very keen to generate new knowledge. Moreover, I have already learnt that PRTP (Professional Research Training Programs) is trying to address training on how to conduct research, how to publish article in renowned journals that are very impressive. Thanks and Well wishes for such a wonderful job.",
  },
];
