import React from "react";
import { Container, Row, Col } from "react-bootstrap";

const Facility = () => {
  return (
    <Container>
      <Row
        xs={1}
        sm={2}
        md={2}
        xl={3}
        xxl={3}
        className="justify-content-around mt-5"
      >
        {Facilitys.map((facility, idx) => (
          <Col key={idx} className="text-center">
            <picture>
              <img
                src={facility.img}
                alt="Ask question?"
                title="Ask question?"
              />
            </picture>
            <h5>{facility.title}</h5>
            <p>{facility.details}</p>
          </Col>
        ))}
      </Row>
    </Container>
  );
};

export default Facility;

const Facilitys = [
  {
    img: "https://cdn.10minuteschool.com/images/Landing_page/Video%20lecture.svg",
    title: "Video lectures",
    details: "Learn at your own pace from our videos",
  },
  {
    img: "https://cdn.10minuteschool.com/images/Landing_page/LIve%20Class.svg",
    title: "Attend live classes",
    details: "Daily live classes help you maintain your routine",
  },
  {
    img: "https://cdn.10minuteschool.com/images/Landing_page/ask%20question.svg",
    title: "Ask question?",
    details: "24 hours live within our platform",
  },
  {
    img: "https://cdn.10minuteschool.com/images/Landing_page/Exercise.svg",
    title: "Practice anytime",
    details: "Instant scores, explanations and leaderboards!",
  },
  {
    img: "https://cdn.10minuteschool.com/images/Landing_page/Lecture%20sheet.svg",
    title: "Every note you will ever need",
    details: "Access our lecture sheets and interactive books",
  },
  {
    img: "https://cdn.10minuteschool.com/images/Landing_page/Daily%20result%20and%20leaderboard.svg",
    title: "Own classroom",
    details: "Each student has a separate classroom with  a research rider",
  },
];
