import React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { itemData } from "../../../Data/T_data";
import { Paper } from "@mui/material";
import Typewriter from "typewriter-effect";
import style from "./Engage.module.css";
import Image from "next/image";
import banner from "../../../assets/banner/banner-1.png";
import Link from "next/link";

const Engage = () => {
  const font = {
    fontWeight: "600",
    fontSize: "18px",
    lineHeight: "150%",
  };

  const detailFont = {
    fontWeight: "500",
    fontSize: "14px",
  };

  return (
    <div>
      <Container className="my-3 py-3" id="research">
        <Paper
          className={`${style.banner_img} d-flex align-items-center justify-content-around flex-wrap`}
        >
          <h4 className="text-center text-white fw-bold">
            Engaging in Research Rider
            <Typewriter
              options={{
                strings: [
                  "<span style='color: #27ae60;'>will support to</span>",
                ],
                autoStart: true,
                loop: true,
              }}
            />
          </h4>

          <Image
            src={banner}
            width={300}
            height={190}
            alt="Engaging in Research Rider"
          />
        </Paper>

        <Row xs={1} sm={2} md={2} xl={4} xxl={5} className="pt-4">
          {itemData?.map((engage, idx) => (
            <Col key={idx} className={style.zoom}>
              {engage.title === "Research Training" ? (
                <Link href="/prtp">
                  <Paper
                    key={idx}
                    className="text-center p-3 rounded-4 m-2"
                    style={{ height: "14.5rem" }}
                  >
                    <img
                      width={100}
                      height={100}
                      src={engage.img.src}
                      alt={engage.title}
                      title={engage.title}
                    />
                    <h3 className="mt-2" style={font}>
                      {engage.title}
                    </h3>
                    <p style={detailFont}>{engage.details}</p>
                  </Paper>
                </Link>
              ) : (
                <>
                  {engage.title === "Research Rider Jobs" ? (
                    <Link href="/careers">
                      <Paper
                        key={idx}
                        className="text-center p-3 rounded-4 m-2"
                        style={{ height: "14.5rem" }}
                      >
                        <img
                          width={100}
                          height={100}
                          src={engage.img.src}
                          alt={engage.title}
                          title={engage.title}
                        />
                        <h3 className="mt-2" style={font}>
                          {engage.title}
                        </h3>
                        <p style={detailFont}>{engage.details}</p>
                      </Paper>
                    </Link>
                  ) : (
                    <Paper
                      key={idx}
                      className="text-center p-3 rounded-4 m-2"
                      style={{ height: "14.5rem" }}
                    >
                      <img
                        width={100}
                        height={100}
                        src={engage.img.src}
                        alt={engage.title}
                        title={engage.title}
                      />
                      <h3 className="mt-2" style={font}>
                        {engage.title}
                      </h3>
                      <p style={detailFont}>{engage.details}</p>
                    </Paper>
                  )}
                </>
              )}
            </Col>
          ))}
        </Row>
      </Container>
    </div>
  );
};

export default Engage;
