import React from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { Button } from "@mui/material";
import style from "./PRTPAds.module.css";
import Lottie from "lottie-react";
import AOS from "aos";
import "aos/dist/aos.css";
import Link from "next/link";
import bannerImg from "../../PRTPLanding/PRTPBanner/banner.json";
import SignUpModal from "../../ModalContent/SignUpModal/SignUpModal";
import { Container } from "react-bootstrap";

const PRTPAds = ({ isLoggedIn }) => {
  //sign up modal
  const [openSingUp, setOpenSignUp] = React.useState(false);
  const handleSignUpClose = () => {
    setOpenSignUp(false);
  };

  const handleSignUpOpen = () => {
    setOpenSignUp(true);
  };

  React.useEffect(() => {
    AOS.init();
  }, []);

  return (
    <Container className={`${style.container}`} fluid>
      <Row className={`justify-content-between align-items-center g-3`}>
        <Col sm={6} md={7} className="justify-content-center">
          <Lottie
            className={`${style.animSize}`}
            animationData={bannerImg}
            loop={true}
          />
        </Col>
        <Col
          sm={6}
          md={5}
          className="d-flex flex-column align-items-start justify-content-center my-2"
        >
          <Link href="/prtp">
            <h2 className={`${style.title} fw-bold`}>
              Professional <br /> Research Training Program (PRTP)
            </h2>
          </Link>

          {isLoggedIn ? (
            <Link href="/course/payment/4">
              <Button
                className={`${style.btn_hover} ${style.btn_color}`}
                variant="contained"
              >
                Join Us
              </Button>
            </Link>
          ) : (
            <Button
              onClick={() => setOpenSignUp(true)}
              className={`${style.btn_hover} ${style.btn_color}`}
              variant="contained"
            >
              Join Us
            </Button>
          )}
        </Col>
      </Row>
      <>
        <SignUpModal
          open={openSingUp}
          handleClose={handleSignUpClose}
          handleOpen={handleSignUpOpen}
          courseID="4"
        />
      </>
    </Container>
  );
};

export default PRTPAds;
