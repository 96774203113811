import React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { Button } from "react-bootstrap";
import style from "./Banner.module.css";
import Link from "next/link";

const Banner = ({ isLoggedIn }) => {
  const intro =
    "https://firebasestorage.googleapis.com/v0/b/rr-intro.appspot.com/o/rr-intro.mp4?alt=media&token=24828824-01b9-4834-a5d0-f162410d33dd";

  return (
    <Container className={`${style.main_container}`} fluid>
      <Row
        className={`${style.row_box} justify-content-center align-items-center flex-wrap g-4`}
      >
        <Col xs={12} sm={12} md={7} xl={6} className="text-white">
          <h2 className={`${style.tag_line}`}>Grow up Being a Researcher</h2>
          <span className={`${style.more_text}`}>
            Access over million&apos;s of publication pages and stay up to date
            with what&apos;s happening in your field.
          </span>

          {isLoggedIn ? (
            <Link href={`/user/${localStorage.id}`}>
              <Button
                className={`${style.btn_hover} ${style.btn_color} d-block`}
                variant="light"
              >
                Join For Free
              </Button>
            </Link>
          ) : (
            <Link href="/user/registration">
              <Button
                className={`${style.btn_hover} ${style.btn_color} d-block`}
                variant="light"
              >
                Join For Free
              </Button>
            </Link>
          )}
        </Col>
        <Col xs={12} sm={12} md={5} xl={4} className={`${style.video_box}`}>
          <video
            className={`${style.video_frame} rounded-2`}
            width="100%"
            controls
            // autoPlay={true}
            loop
            muted
          >
            <source src={intro} type="video/mp4" />
          </video>
        </Col>
      </Row>
    </Container>
  );
};

export default Banner;
