import React from "react";
import { Container, Row, Col, Card } from "react-bootstrap";
import style from "./CourseAchive.module.css";
import Lottie from "lottie-react";
import achive from "./achivement.json";
import { Paper } from "@mui/material";

const CourseAchive = () => {
  return (
    <Container>
      <div className="text-center mt-5">
        <h2 className="fw-bold">What we have?</h2>
      </div>
      <Row className="justify-content-center align-items-center flex-wrap g-2">
        <Col md={6}>
          <Lottie
            className={`${style.animSize}`}
            animationData={achive}
            loop={true}
          />
        </Col>
        <Col md={6}>
          <Row xs={2} md={2} className="g-2">
            {AchiveData.map((data, idx) => (
              <Col key={idx}>
                <Paper
                  style={{ background: `${data.color}` }}
                  className=" text-center rounded-3 py-2"
                >
                  <Card.Img
                    className={style.icon_size}
                    variant="top"
                    src={data.img}
                    alt={data.info}
                    title={data.info}
                  />
                  <Card.Body>
                    <Card.Title className="fs-2 fw-bold">{data.num}</Card.Title>
                    <Card.Text className="fw-bold fs-5">{data.info}</Card.Text>
                  </Card.Body>
                </Paper>
              </Col>
            ))}
          </Row>
        </Col>
      </Row>
    </Container>
  );
};

export default CourseAchive;

const AchiveData = [
  {
    img: "https://img.icons8.com/external-flaticons-lineal-color-flat-icons/64/000000/external-teacher-back-to-school-flaticons-lineal-color-flat-icons-19.png",
    num: "100 +",
    info: "Teachers",
    color: "#f9f7e6",
  },
  {
    img: "https://img.icons8.com/external-flaticons-flat-flat-icons/64/000000/external-course-university-flaticons-flat-flat-icons-5.png",
    num: "100 +",
    info: "Courses",
    color: "#e3fdf2",
  },
  {
    img: "https://img.icons8.com/external-flaticons-lineal-color-flat-icons/64/000000/external-students-university-flaticons-lineal-color-flat-icons.png",
    num: "1M +",
    info: "Publication",
    color: "#fff2f2",
  },
  {
    img: "https://img.icons8.com/clouds/100/000000/news.png",
    num: "1K +",
    info: "Students",
    color: "#eae9f8",
  },
];
