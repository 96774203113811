import Head from "next/head";
import LandingPage from "../Components/Landing/LandingPage";

export default function Home({ isLoggedIn, adsId }) {
  return (
    <div>
      <Head>
        <link rel="icon" href="/favicon.svg" />
        <meta name="theme-color" content="#ffffff" />
        <link rel="apple-touch-icon" href="/favicon.svg" />
      </Head>
      <main>
        <LandingPage isLoggedIn={isLoggedIn} adsId={adsId} />
      </main>
    </div>
  );
}
