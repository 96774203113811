import img1 from "../assets/landing/research_hub.svg";
import img2 from "../assets/landing/research_rider_journal.svg";
import img3 from "../assets/prtp/without_text.svg";
import img4 from "../assets/landing/live_serach.svg";
import img5 from "../assets/landing/research_rider_jobs.svg";
import img6 from "../assets/landing/skill_training_center.svg";
import img7 from "../assets/landing/ICT_Solution.svg";
import img8 from "../assets/landing/idea_solution.svg";
import img9 from "../assets/landing/Numerous_Thoughts_icon.svg";
import img10 from "../assets/landing/Research_Summaries.svg";
import img11 from "../assets/landing/post.svg";
import img12 from "../assets/landing/share.svg";
import img13 from "../assets/landing/publish.svg";
import img14 from "../assets/landing/Research_Communication.svg";
import img15 from "../assets/landing/create_grops.svg";
import img16 from "../assets/landing/course_icon.svg";
import img17 from "../assets/landing/enroll_icon.svg";
import img18 from "../assets/landing/create_course.svg";
import img19 from "../assets/landing/make_cv_icon.svg";
import img20 from "../assets/landing/freelancing.svg";

export const itemData = [
  {
    img: img1,
    title: "Research Hub",
    details: "Be connected with the worldwide research hub.",
  },
  {
    img: img3,
    title: "Research Training",
    details: "Professional research training program",
  },

  {
    img: img2,
    title: "Research Rider Journal",
    details: "Which is a peer reviewed article publication.",
  },

  {
    img: img4,
    title: "Live Search",
    details: "Which is a platform of demanded online class for any solution.",
  },
  {
    img: img5,
    title: "Research Rider Jobs",
    details: "Which is a job searching platform.",
  },
  {
    img: img6,
    title: "Skill Training Center",
    details:
      "Which provides research, profession and academic skill development programs.",
  },
  {
    img: img7,
    title: "ICT Solution",
    details:
      "Which develops software and application; sets up data center and networking systems.",
  },
  {
    img: img8,
    title: "Idea Solution",
    details:
      "Which assists any idea with relation to ICT to startup a business project.",
  },
  {
    img: img9,
    title: "Numerous Thoughts",
    details: "Browse numerous thoughts worldwide",
  },

  {
    img: img10,
    title: "Research Summaries",
    details: "Enjoy various research summaries worldwide.",
  },
  {
    img: img11,
    title: "Post",
    details: "Post your research summaries",
  },
  {
    img: img12,
    title: "Share",
    details: "Share your own thoughts",
  },
  {
    img: img13,
    title: "Publish",
    details: "Publish/share your research article",
  },
  {
    img: img14,
    title: "Research Communication",
    details: "Conduct your desired research communication worldwide",
  },
  {
    img: img15,
    title: "Create Groups",
    details: "Create your groups & conduct various group activities",
  },
  {
    img: img16,
    title: "Courses",
    details: "Browse numerous courses worldwide",
  },
  {
    img: img17,
    title: "Enroll",
    details: "Enroll in a number of courses",
  },
  {
    img: img18,
    title: "Create Courses",
    details: "Create courses according to your expertise",
  },
  {
    img: img19,
    title: "Make CV",
    details: "Make a CV & use it according to your purpose worldwide",
  },
  {
    img: img20,
    title: "Research Rider Freelancing",
    details: "Get the opportunities of earning along with learning",
  },
];
