import React from "react";
import styles from "./courses.module.css";
import { Container, Button } from "react-bootstrap";
import { Paper } from "@mui/material";
import banner from "../../../assets/banner/banner-2.png";
import Image from "next/image";
import CourseAchive from "../CourseAchive/CourseAchive";
import Facility from "../Facility/Facility";
import Link from "next/link";
import AutoPlayCourse from "../AutoPlayCourse/AutoPlayCourse";

const Courses = () => {
  return (
    <Container>
      <Paper elevation={8} className={`${styles.banner_img} p-2`}>
        <div>
          <h4 className="fw-bold text-warning">SKILL DEVELOPMENT COURSES</h4>
          <small className="fw-bold text-white">
            We provides research, professional, and academic skill development
            programs via online and offline
          </small>
        </div>
        <div className={styles.img_size}>
          <Image src={banner} alt="banner ing" />
        </div>
      </Paper>
      <Facility />
      <AutoPlayCourse />
      <CourseAchive />
      <div className="text-center mt-3 mb-5">
        <Link href="/course">
          <Button
            className={`${styles.button_hover} ${styles.color}`}
            variant="light"
          >
            Explore All Courses
          </Button>
        </Link>
      </div>
    </Container>
  );
};

export default Courses;
