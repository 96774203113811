import React from "react";
import { Card } from "react-bootstrap";
// swiper slider
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import { Pagination, Autoplay } from "swiper";
//Date Format
import moment from "moment";
//Custom Css
import styles from "./AutoPlayCourse.module.css";
import * as constants from "../../constants";
import Link from "next/link";
const BASE_URL = constants.BASE_URL;

const AutoPlayCourse = () => {
  const [allCourses, setAllCourses] = React.useState([]);

  //getting all courses data
  React.useEffect(() => {
    fetch(`${BASE_URL}/course/all/?search=`)
      .then((res) => res.json())
      .then((data) => {
        if (data.message) {
          alert({ data: data.message });
        } else {
          data.length &&
            data.sort((a, b) => {
              return new Date(b.created_date) - new Date(a.created_date);
            });
          setAllCourses(data);
        }
      });
  }, []);

  const params = {
    pagination: {
      clickable: true,
      dynamicBullets: true,
    },
    autoplay: {
      delay: 2000,
      disableOnInteraction: false,
    },
    spaceBetween: 30,
    modules: [Pagination, Autoplay],
    breakpoints: {
      320: {
        slidesPerView: 1,
        spaceBetween: 10,
      },
      576: {
        slidesPerView: 2,
        spaceBetween: 10,
      },
      640: {
        slidesPerView: 1,
        spaceBetween: 10,
      },
      768: {
        slidesPerView: 2,
        spaceBetween: 20,
      },
      1024: {
        slidesPerView: 4,
        spaceBetween: 30,
      },
    },
  };

  return (
    <Swiper {...params} className="pt-4">
      {allCourses.map((course, idx) => (
        <SwiperSlide className="mb-5" key={idx}>
          <Link href={`/course/${course.id}`}>
            <Card className={`${styles.card_size}`}>
              <Card.Img
                style={{ aspectRatio: "16/9" }}
                variant="top"
                src={course.cover_pic}
              />
              <Card.Body>
                <Card.Title
                  className={`${styles.card_title} text-truncate p-2`}
                >
                  {course.name}
                </Card.Title>
                <Card.Text>
                  <div className="mx-3 text-danger fw-bold pb-1">
                    &nbsp; Live Class{" "}
                  </div>
                  <div className={styles.video_icon}>
                    <div className={styles.circle_inner}></div>
                  </div>
                  Enrollment date:{" "}
                  {moment(course.course_enroll_end_date).format("ll")}
                  <br />
                  Course start: {moment(course.start_date).format("ll")}
                </Card.Text>
              </Card.Body>
              <Card.Footer className="d-flex justify-content-around align-items-center">
                <b>
                  <span className={`${styles.fee}`}>&#x9F3;</span>
                  {course.enrollment_fee}
                </b>
                <b>Enroll Now</b>
              </Card.Footer>
            </Card>
          </Link>
        </SwiperSlide>
      ))}
    </Swiper>
  );
};

export default AutoPlayCourse;
