import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";
import * as constants from "../../constants";
import Avatar, { ConfigProvider } from "react-avatar";
import { Pagination, Autoplay } from "swiper";

export default function Topic() {
  const [categories, setCategories] = React.useState([]);
  const [isLoading, setLoading] = React.useState(false);

  const baseURL = constants.BASE_URL;

  //Getting all Course categories
  React.useEffect(() => {
    setLoading(true);
    fetch(`${baseURL}/course/categories/`)
      .then((res) => res.json())
      .then((data) => {
        setCategories(data);
        setLoading(false);
      });
  }, [baseURL]);
  return (
    <Container className="my-4">
      <Row className="justify-content-center">
        <Col>
          <div className="text-center py-4">
            <h1 className="text-primary">
              <b>Choose your Topics</b>
            </h1>
            <span>Explore your knowledge worldwide with these topic!</span>
          </div>
          <Swiper
            slidesPerGroup={2}
            autoplay={{
              delay: 5000,
              disableOnInteraction: false,
            }}
            pagination={{
              clickable: true,
              dynamicBullets: true,
            }}
            breakpoints={{
              640: {
                slidesPerView: 2,
                spaceBetween: 20,
              },

              540: {
                slidesPerView: 2,
                spaceBetween: 40,
              },
              768: {
                slidesPerView: 3,
                spaceBetween: 40,
              },

              1024: {
                slidesPerView: 4,
                spaceBetween: 50,
              },
              1440: {
                slidesPerView: 5,
                spaceBetween: 50,
              },
            }}
            modules={[Pagination, Autoplay]}
            className="mySwiper"
          >
            {categories.map((element, idx) => (
              <SwiperSlide
                key={idx}
                className="d-flex align-items-center justify-content-center "
              >
                <div className="mb-3 text-center">
                  <ConfigProvider
                    key={idx}
                    colors={["#ffcccc", "#ffff00", "#cce6ff", "#ccffeb"]}
                  >
                    <Avatar
                      name={element.name}
                      fgColor="#00061a"
                      maxInitials={2}
                      round="20px"
                      size="180"
                    />
                  </ConfigProvider>
                  <span className="d-block fw-bold">{element.name}</span>
                </div>
              </SwiperSlide>
            ))}
          </Swiper>
        </Col>
      </Row>
    </Container>
  );
}

// colors={["#ffcccc", "#ffff00", "#cce6ff", "#ccffeb"]}
